:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media (prefers-reduced-motion: reduce), print {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  0%, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translateZ(0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  0% {
    transform: scaleX(1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  0%, to {
    transform: translateZ(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: translateZ(0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate(-5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate(3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate(-3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate(2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate(-1deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  0%, 11.1%, to {
    transform: translateZ(0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  to {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(-1turn);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scaleX(1)translateZ(150px)rotateY(-170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translateZ(0)rotateY(0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scaleX(1)translateZ(0)rotateY(0);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateX(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateX(10deg);
  }

  80% {
    transform: perspective(400px)rotateX(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotateY(-20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotateY(10deg);
  }

  80% {
    transform: perspective(400px)rotateY(-5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateX(-20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotateY(-15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    opacity: 0;
    transform: rotate(-200deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    transform: rotate(-45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    transform: rotate(-90deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(-45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate(90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate(80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate(-120deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate(120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  0% {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  0% {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translateZ(0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateZ(0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-8 {
  padding: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

.ring-gray-900\/5 {
  --tw-ring-color: #1118270d;
}

html, body, #__next, #app {
  height: 100% !important;
  min-height: 100% !important;
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-indigo-600:focus-visible {
  outline-color: #4f46e5;
}

@media (width >= 640px) {
  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

/*# sourceMappingURL=index.1374eff9.css.map */
