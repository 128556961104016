@import 'npm:animate.css/animate.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100% !important;
  min-height: 100% !important;
}

body {
  height: 100% !important;
  min-height: 100% !important;
}

#__next {
  height: 100% !important;
  min-height: 100% !important;
}

#app {
  height: 100% !important;
  min-height: 100% !important;
}
